import React, { Fragment } from "react";
import { Routes, Route, Navigate } from "react-router-dom"

import './assets/scss/themes.scss';
import NonAuthLayout from "./Layouts/NonAuthLayout";
import VerticalLayout from "./Layouts/index";

import { ToastContainer, toast, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import LoginScreen from "./screens/LoginScreen";
import PasswordForgotScreen from "./screens/PasswordForgotScreen";
import PasswordResetScreen from "./screens/PasswordResetScreen";
import LogoutScreen from "./screens/LogoutScreen";
import DashboardScreen from "./screens/DashboardScreen";
import KanbanScreen from "./screens/KanbanScreen";
import NotificationSearchScreen from "./screens/NotificationSearchScreen";
import EmailSearchScreen from "./screens/EmailSearchScreen";
import NotFoundScreen from "./screens/NotFoundScreen";
import NotAuthorized from "./screens/NotAuthorized";
import InvalidCompany from "./screens/InvalidCompany";

import UserSearchScreen from "./screens/UserSearchScreen";
import UserEditScreen from "./screens/UserEditScreen";
import UserProfileScreen from "./screens/UserProfileScreen";

import CompanySearchScreen from "./screens/CompanySearchScreen";
import CompanyEditScreen from "./screens/CompanyEditScreen";

import TicketSearchScreen from "./screens/TicketSearchScreen";
import TicketNotifySearchScreen from "./screens/TicketNotifySearchScreen";
import TicketSearchScreenREVW from "./screens/TicketSearchScreenREVW";
import TicketSearchScreenSCOC from "./screens/TicketSearchScreenSCOC";
import TicketSearchScreenPCOC from "./screens/TicketSearchScreenPCOC";
import TicketSearchScreenFA from "./screens/TicketSearchScreenFA";
import TicketSearchScreenCOS from "./screens/TicketSearchScreenCOS";
import TicketAddScreen from "./screens/TicketAddScreen";
import TicketEditScreen from "./screens/TicketEditScreen";

import DocumentSearchScreen from "./screens/DocumentSearchScreen";

import PaymentSearchScreen from "./screens/PaymentSearchScreen";
import PaymentEditScreen from "./screens/PaymentEditScreen";

import CustomerSearchScreen from "./screens/CustomerSearchScreen";
import CustomerEditScreen from "./screens/CustomerEditScreen";

import VendorSearchScreen from "./screens/VendorSearchScreen";
import VendorEditScreen from "./screens/VendorEditScreen";

import GroupSearchScreen from "./screens/GroupSearchScreen";
import GroupEditScreen from "./screens/GroupEditScreen";

import ProductSearchScreen from "./screens/ProductSearchScreen";
import ProductEditScreen from "./screens/ProductEditScreen";

import LabReportScreen from "./screens/LabReportScreen";
import LabReportAddScreen  from "./screens/LabReportAddScreen";
import LabReportEditScreen  from "./screens/LabReportEditScreen";

import AuditSearchScreen  from "./screens/AuditSearchScreen";

import IsoDashboardScreen from "./screens/IsoDashboardScreen";
import IsoRequestAddScreen from "./screens/IsoRequestAddScreen";
import IsoRequestEditScreen from "./screens/IsoRequestEditScreen";
import IsoRequestSearchScreen from "./screens/IsoRequestSearchScreen";
import IsoRequestNotifySearchScreen from "./screens/IsoRequestNotifySearchScreen";
import IsoRequestCertificateView from "./screens/IsoRequestCertificateView";
import AuditorSearchScreen from "./screens/AuditorSearchScreen";
import AuditorEditScreen from "./screens/AuditorEditScreen";

import { useDispatch, useSelector } from "react-redux";
import * as commonActions from "./actions/CommonActions";

const  App = () => {
  const dispatch = useDispatch();

  const user = useSelector((state) => state.login.user);
  const successMessage = useSelector((state) => state.common.successMessage);
  const errorMessage = useSelector((state) => state.common.errorMessage);

  React.useEffect(() => {
    if(successMessage) {
      toast.success(successMessage.toString(), {
        onClose: () => dispatch(commonActions.setSuccessMessage(""))
      });
    }
  }, [successMessage]);

  React.useEffect(() => {
    if(errorMessage) {
      toast.error(<div style={{whiteSpace:"pre-wrap"}}>{errorMessage.toString()}</div>, {
        onClose: () => dispatch(commonActions.setErrorMessage(""))
      });
    }
  }, [errorMessage]);

  if(user) {
    return(
      <Fragment>
        <Routes>
          <Route exact path="/" 
            element={
              <VerticalLayout>{user.defaultModule === "ISO" ? <IsoDashboardScreen /> : <KanbanScreen />}</VerticalLayout>
            }
          />
          <Route exact path="/dashboard" 
            element={
              <VerticalLayout><DashboardScreen /></VerticalLayout>
            }
          />
          <Route path="/notifications" 
            element={
              <VerticalLayout><NotificationSearchScreen /></VerticalLayout>
            }
          />
          <Route path="/emails" 
            element={
              <VerticalLayout><EmailSearchScreen /></VerticalLayout>
            }
          />
          <Route path="/requests" 
            element={
              <VerticalLayout><TicketSearchScreen /></VerticalLayout>
            }
          />
          <Route path="/request-notifications" 
            element={
              <VerticalLayout><TicketNotifySearchScreen /></VerticalLayout>
            }
          />
          <Route path="/revw-requests" 
            element={
              <VerticalLayout><TicketSearchScreenREVW /></VerticalLayout>
            }
          />
          <Route path="/scoc-requests" 
            element={
              <VerticalLayout><TicketSearchScreenSCOC /></VerticalLayout>
            }
          />
          <Route path="/pcoc-requests" 
            element={
              <VerticalLayout><TicketSearchScreenPCOC /></VerticalLayout>
            }
          />
          <Route path="/fa-requests" 
            element={
              <VerticalLayout><TicketSearchScreenFA /></VerticalLayout>
            }
          />
          <Route path="/cos-requests" 
            element={
              <VerticalLayout><TicketSearchScreenCOS /></VerticalLayout>
            }
          />
          <Route path="/request-add" 
            element={
              <VerticalLayout><TicketAddScreen /></VerticalLayout>
            }
          />
          <Route path="/request-add/:id" 
            element={
              <VerticalLayout><TicketAddScreen /></VerticalLayout>
            }
          />
          <Route path="/request-edit/:id" 
            element={
              <VerticalLayout><TicketEditScreen /></VerticalLayout>
            }
          />
          <Route path="/iso-requests" 
            element={
              <VerticalLayout><IsoRequestSearchScreen /></VerticalLayout>
            }
          />
          <Route path="/iso-request-notifications" 
            element={
              <VerticalLayout><IsoRequestNotifySearchScreen /></VerticalLayout>
            }
          />
          <Route path="/iso-request-add" 
            element={
              <VerticalLayout><IsoRequestAddScreen /></VerticalLayout>
            }
          />
          <Route path="/iso-request-edit/:id" 
            element={
              <VerticalLayout><IsoRequestEditScreen /></VerticalLayout>
            }
          />
          <Route path="/iso-request-certificate/:id" 
            element={
              <VerticalLayout><IsoRequestCertificateView /></VerticalLayout>
            }
          />
          <Route path="/auditors" 
            element={
              <VerticalLayout><AuditorSearchScreen /></VerticalLayout>
            }
          />
          <Route path="/auditor-edit/:id" 
            element={
              <VerticalLayout><AuditorEditScreen /></VerticalLayout>
            }
          />
          <Route path="/documents" 
            element={
              <VerticalLayout><DocumentSearchScreen /></VerticalLayout>
            }
          />
          <Route path="/payments" 
            element={
              <VerticalLayout><PaymentSearchScreen /></VerticalLayout>
            }
          />
          <Route path="/payment-edit/:id" 
            element={
              <VerticalLayout><PaymentEditScreen /></VerticalLayout>
            }
          />
          <Route path="/users" 
            element={
              <VerticalLayout><UserSearchScreen /></VerticalLayout>
            }
          />
          <Route path="/user-edit/:id" 
            element={
              <VerticalLayout><UserEditScreen /></VerticalLayout>
            }
          />
          <Route path="/user-add" 
            element={
              <VerticalLayout><UserEditScreen /></VerticalLayout>
            }
          />
          <Route path="/profile" 
            element={
              <VerticalLayout><UserProfileScreen /></VerticalLayout>
            }
          />
          <Route path="/companies" 
            element={
              <VerticalLayout><CompanySearchScreen /></VerticalLayout>
            }
          />
          <Route path="/company-edit/:id" 
            element={
              <VerticalLayout><CompanyEditScreen /></VerticalLayout>
            }
          />
          <Route path="/company-add" 
            element={
              <VerticalLayout><CompanyEditScreen /></VerticalLayout>
            }
          />
          <Route path="/customers" 
            element={
              <VerticalLayout><CustomerSearchScreen /></VerticalLayout>
            }
          />
          <Route path="/customer-edit/:id" 
            element={
              <VerticalLayout><CustomerEditScreen /></VerticalLayout>
            }
          />
          <Route path="/customer-add" 
            element={
              <VerticalLayout><CustomerEditScreen /></VerticalLayout>
            }
          />
          <Route path="/vendor-search/:type" 
            element={
              <VerticalLayout><VendorSearchScreen /></VerticalLayout>
            }
          />
          <Route path="/vendor-edit/:id" 
            element={
              <VerticalLayout><VendorEditScreen /></VerticalLayout>
            }
          />
          <Route path="/vendor-add/:type" 
            element={
              <VerticalLayout><VendorEditScreen /></VerticalLayout>
            }
          />
          <Route path="/groups" 
            element={
              <VerticalLayout><GroupSearchScreen /></VerticalLayout>
            }
          />
          <Route path="/group-edit/:id" 
            element={
              <VerticalLayout><GroupEditScreen /></VerticalLayout>
            }
          />
          <Route path="/group-add" 
            element={
              <VerticalLayout><GroupEditScreen /></VerticalLayout>
            }
          />
          <Route path="/products" 
            element={
              <VerticalLayout><ProductSearchScreen /></VerticalLayout>
            }
          />
          <Route path="/product-edit/:id" 
            element={
              <VerticalLayout><ProductEditScreen /></VerticalLayout>
            }
          />
          <Route path="/product-add" 
            element={
              <VerticalLayout><ProductEditScreen /></VerticalLayout>
            }
          />
          <Route path="/lab-reports" 
            element={
              <VerticalLayout><LabReportScreen /></VerticalLayout>
            }
          />
          <Route path="/lab-report-add" 
            element={
              <VerticalLayout><LabReportAddScreen /></VerticalLayout>
            }
          />
          <Route path="/lab-report-edit/:id" 
            element={
              <VerticalLayout><LabReportEditScreen /></VerticalLayout>
            }
          />
          <Route path="/audits" 
            element={
              <VerticalLayout><AuditSearchScreen /></VerticalLayout>
            }
          />
          <Route path="/not-authorized" 
            element={
              <VerticalLayout><NotAuthorized /></VerticalLayout>
            }
          />
          <Route path="/invalid-company" 
            element={
              <VerticalLayout><InvalidCompany /></VerticalLayout>
            }
          />
          <Route path="/invalid-company/:type/:companyId/:moduleName/:id" 
            element={
              <VerticalLayout><InvalidCompany /></VerticalLayout>
            }
          />
          <Route path="/logout" 
            element={
              <NonAuthLayout><LogoutScreen /></NonAuthLayout>
            }
          />
          <Route path="/not-found"
            element={
              <VerticalLayout><NotFoundScreen /></VerticalLayout>
            }
          />
          <Route path="*" element={<Navigate to ="/not-found" />}/>
        </Routes>
              
        <a className="scroll-to-top rounded" href="#page-top">
            <i className="fas fa-angle-up"></i>
        </a>
        
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss={true}
          draggable={true}
          pauseOnHover={true}
          theme={"colored"}
          transition={Slide}
          style={{width:"30%"}}
        />
      </Fragment>
    );
  }

  return (
    <Fragment>
      <Routes>
        <Route exact path="/" 
          element={
            <NonAuthLayout><LoginScreen /></NonAuthLayout>
          }
        />
        <Route exact path="/forgot-password" 
          element={
            <NonAuthLayout><PasswordForgotScreen /></NonAuthLayout>
          }
        />
        <Route path="/reset-password/:id" 
          element={
            <NonAuthLayout><PasswordResetScreen /></NonAuthLayout>
          }
        />
        <Route path="/iso-request-certificate/:id" 
          element={
            <NonAuthLayout><IsoRequestCertificateView /></NonAuthLayout>
          }
        />
        <Route path="/logout" 
          element={
            <NonAuthLayout><LogoutScreen /></NonAuthLayout>
          }
        />
      </Routes>
      <ToastContainer
        position="bottom-center"
        autoClose={3000}
        hideProgressBar={false}
        newestOnTop={true}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss={true}
        draggable={true}
        pauseOnHover={true}
        theme={"colored"}
        transition={Slide}
        style={{width:"30%"}}
      />
    </Fragment>
  );
}

export default App;
