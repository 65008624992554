import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import classnames from "classnames";
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Spinner, Form, Nav, NavItem, NavLink, TabContent, TabPane, Progress } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';
import { Typeahead } from 'react-bootstrap-typeahead';
import 'react-bootstrap-typeahead/css/Typeahead.css';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";

import { useDispatch, useSelector } from "react-redux";
import * as ticketActions from "../actions/TicketActions";
import * as commonActions from "../actions/CommonActions";

const TicketAddScreen  = () => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.ticket.loadingFBO);
    const fbo = useSelector((state) => state.ticket.fbo);
    const serviceList = useSelector((state) => state.login.data.serviceList);
    const technicalRegulationList = useSelector((state) => state.login.data.technicalRegulationList);

    const [fboState, setFBOState] = React.useState(fbo);
    const [selectedTechnicalRegulation, setSelectedTechnicalRegulation] = React.useState([]);
        
    const [progressbarvalue, setprogressbarvalue] = React.useState(0);
    const [activeTab, setactiveTab] = React.useState(1);
    const [passedSteps, setPassedSteps] = React.useState([1]);

    React.useEffect(() => {
	    dispatch(ticketActions.loadTicket(null, navigate));
    }, []);

    React.useEffect(() => {
		if(id && fbo && fbo.ticket) {
            fbo.ticket.serviceId = id;
        }

        setFBOState(fbo);

        if(fbo != null) {
            reset(fbo.ticket);
        }
        clearErrors();
    }, [fbo]);

    async function toggleTab(tab, value) {
        if(value === 100) {
            const valid = await trigger(['serviceId', 'groupId']);
            if(valid) {
                if(fboState.ticket.groupId === 0) {
                    dispatch(commonActions.setErrorMessage("Please select Group Name"));
                }
                else {
                    if (activeTab !== tab) {
                        var modifiedSteps = [...passedSteps, tab];
            
                        if (tab >= 1 && tab <= 4) {
                            setactiveTab(tab);
                            setPassedSteps(modifiedSteps);
                        }
                    }
            
                    setprogressbarvalue(value);
                }
                
            }
        }
        else {
            if (activeTab !== tab) {
                var modifiedSteps = [...passedSteps, tab];
    
                if (tab >= 1 && tab <= 4) {
                    setactiveTab(tab);
                    setPassedSteps(modifiedSteps);
                }
            }
    
            setprogressbarvalue(value);
        }
        
    }

    const onTicketChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value) {
            if(name === "quantity" || name === "nonRegulatedCount") {
                value = value.replace(/[^0-9]/g, '');
            }
            else if(name === "fob" || name === "price" || name === "vat") {
                value = value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');
            }
            
            copy_fboState.ticket[name] = value;
        }
        else if(value === false) {
            copy_fboState.ticket[name] = false;
        }
        else {
            copy_fboState.ticket[name] = null;
        }

        if(name === "serviceId" ) {
            if(value !== "2") {
                copy_fboState.pcocNumbers = [""];
            }
            else {
                copy_fboState.pcocNumbers = [""];
            }

            copy_fboState.ticket["groupId"] = 0;
            copy_fboState.ticket["quantity"] = 1;
            copy_fboState.ticket["nonRegulated"] = false;
            copy_fboState.ticket["nonRegulatedCount"] = 1;
        }
        
        setFBOState(copy_fboState);
    }

    const onTechnicalRegulationChange = (item) => {
        setSelectedTechnicalRegulation(item);
        
        if(item.length > 0) {
            onTicketChange("technicalRegulation", item[0].description.toString());
        }
        else {
            onTicketChange("technicalRegulation", null);
        }
    }

    const onPcocNumberChange = (index, value) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value) {
            copy_fboState.pcocNumbers[index] = value;
        }
        else {
            copy_fboState.pcocNumbers[index] = null;
        }

        setFBOState(copy_fboState);
    }

    const onPcocNumberAdd = (index) => {
        var copy_fboState = createDeepClone(fboState);
        copy_fboState.pcocNumbers.push("");
        setFBOState(copy_fboState);
    }

    const getGroupList = () => {
        var arrGroupList = [];

        if(fboState) {
            if(fboState.ticket.serviceId === "4") {
                fboState.pdGroupList.forEach((group, i) => {
                    arrGroupList.push(group);
                });
            }
            else if(fboState.ticket.serviceId === "5") {
                fboState.groupList.forEach((group, i) => {
                    if(group.isCosmetic) {
                        arrGroupList.push(group);
                    }
                });
            }
            else {
                fboState.groupList.forEach((group, i) => {
                    arrGroupList.push(group);
                });
            }
        }

        return arrGroupList;
    }

    const onSaveTicket = async() => {
        const valid = await trigger();
        if(valid) {
            dispatch(ticketActions.saveTicket(fboState, "", "", false, navigate));
        }
    }

    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={"Add Ticket"} />
                    <Row>
                        <Col xl={12}>
                            <Card>
                                <CardLoader loading={loading} />
                                <CardBody>
                                    <div className="text-center pt-3 pb-4 mb-1">
                                        <h5>Create New Ticket</h5>
                                    </div>
                                    
                                    <div className="progress-nav mb-4">
                                        <Progress
                                            value={progressbarvalue}
                                            style={{ height: "1px" }}
                                        />

                                        <Nav className="nav-pills progress-bar-tab custom-nav" role="tablist">
                                            <li className="nav-item">
                                                <button type="button" 
                                                    className={classnames(
                                                    {
                                                        active: activeTab === 1,
                                                        done: activeTab <= 2 && activeTab >= 0,
                                                    },
                                                    "rounded-pill nav-link"
                                                    )}
                                                >1</button>
                                            </li>
                                            <li className="nav-item">
                                                <button type="button" 
                                                    className={classnames(
                                                    {
                                                        active: activeTab === 2,
                                                        done: activeTab <= 2 && activeTab > 1,
                                                    },
                                                    "rounded-pill nav-link"
                                                    )}
                                                >2</button>
                                            </li>
                                        </Nav>
                                    </div>

                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId={1}>
                                            <div>
                                                <div className="mb-4">
                                                    <div>
                                                        <h5 className="mb-1">Select Ticket Type</h5>
                                                    </div>
                                                </div>
                                                <Row className="gy-2">
                                                    <Col xxl={3} md={6}>
                                                        <Label className="form-label">Ticket #</Label>
                                                        <input type="text" className="form-control" readOnly={true}
                                                            value={(fboState && fboState.ticket.requestNumber) || ""}
                                                            onChange={e => onTicketChange("requestNumber", e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col xxl={3} md={6}>
                                                        <Label className="form-label">Status</Label>
                                                        <input className="form-control" readOnly={true}
                                                            value={(fboState && fboState.ticket.status) || ""}
                                                            onChange={e => onTicketChange("status", e.target.value)}
                                                        />
                                                    </Col>
                                                    <Col xxl={3} md={6}>
                                                        <Label className="form-label">Ticket Type<span className="required">*</span></Label>
                                                        <select className={`form-select ${errors.serviceId ? "is-invalid" : ""}`}
                                                            value={(fboState && fboState.ticket.serviceId) || ""}
                                                            {...register("serviceId", { required: true, validate: (value) => value > 0 })}
                                                            onChange={e => onTicketChange("serviceId", e.target.value)}
                                                        >
                                                            <option value={0}>...</option>
                                                            {serviceList && serviceList.map(function(item, index){
                                                                return (
                                                                    <option key={index} value={item.id}>{item.name}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </Col>
                                                    <Col xxl={3} md={6}>
                                                        <Label className="form-label">Group Name<span className="required">*</span></Label>
                                                        <select className={`form-select ${errors.groupId ? "is-invalid" : ""}`}
                                                            value={(fboState && fboState.ticket.groupId) || ""}
                                                            {...register("groupId", { required: true, validate: (value) => value > 0 })}
                                                            onChange={e => onTicketChange("groupId", e.target.value)}
                                                        >
                                                            <option value={0}>...</option>
                                                            {getGroupList().map(function(item, index){
                                                                return (
                                                                    <option key={index} value={item.id}>{item.name}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </Col>
                                                    <Col xxl={12} md={12}>
                                                        <Label className="form-label">Comments</Label>
                                                        <input className="form-control"
                                                            value={(fboState && fboState.ticket.description) || ""}
                                                            onChange={e => onTicketChange("description", e.target.value)}
                                                        />
                                                    </Col>
                                                    
                                                    
                                                </Row>
                                            </div>
                                            <div className="d-flex align-items-start gap-3 mt-4">
                                                <button
                                                    disabled={loading}
                                                    type="button"
                                                    className="btn btn-primary btn-label right ms-auto nexttab nexttab"
                                                    onClick={() => {
                                                    toggleTab(activeTab + 1, 100);
                                                    }}
                                                >
                                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                    Next
                                                </button>
                                            </div>
                                        </TabPane>

                                        <TabPane tabId={2}>
                                            <div>
                                                <div className="mb-4">
                                                    <div>
                                                        <h5 className="mb-1">Fill Ticket Information</h5>
                                                    </div>
                                                </div>
                                                <Row className="gy-2">
                                                    <Col xxl={3} md={6}>
                                                        <Label className="form-label">CRM Name<span className="required">*</span></Label>
                                                        <select className={`form-select ${errors.crmName ? "is-invalid" : ""}`}
                                                            value={(fboState && fboState.ticket.crmName) || ""}
                                                            {...register("crmName", { required: true })}
                                                            onChange={e => onTicketChange("crmName", e.target.value)}
                                                        >
                                                            <option value="">...</option>
                                                            {fboState && fboState.crmList.map(function(item, index){
                                                                return (
                                                                    <option key={index} value={item.crmName}>{item.crmLabel}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </Col>
                                                    <Col xxl={3} md={6}>
                                                        <Label className="form-label">Invoice Country<span className="required">*</span></Label>
                                                        <select className={`form-select ${errors.crmCountry ? "is-invalid" : ""}`}
                                                            value={(fboState && fboState.ticket.crmCountry) || ""}
                                                            {...register("crmCountry", { required: true })}
                                                            onChange={e => onTicketChange("crmCountry", e.target.value)}
                                                        >
                                                            <option value="">...</option>
                                                            {fboState && fboState.crmCountryList.filter(x => x.crmName == fboState.ticket.crmName).map(function(item, index){
                                                                return (
                                                                    <option key={index} value={item.countryName}>{item.countryLabel}</option>
                                                                );
                                                            })}
                                                        </select>
                                                    </Col>
                                                    <Col xxl={2} md={6}>
                                                        <Label className="form-label">Quantity<span className="required">*</span></Label>
                                                        <input className={`form-control ${errors.quantity ? "is-invalid" : ""}`} readOnly={fboState && (fboState.ticket.serviceId === "4" || fboState.ticket.serviceId === "5")}
                                                            value={(fboState && fboState.ticket.quantity) || ""}
                                                            {...register("quantity", { required: true, valueAsNumber: true, validate: (value) => value > 0 })}
                                                            onChange={e => onTicketChange("quantity", e.target.value)}
                                                        />
                                                    </Col>
                                                    {fboState && fboState.ticket.serviceId === "2" &&
                                                    <Fragment>
                                                        <Col xxl={2} md={6}>
                                                            <Label className="form-label">Non Regulated Included?</Label>
                                                            <div className="form-check pt-2">
                                                                <input type="checkbox" className="form-check-input"
                                                                    checked={(fboState && fboState.ticket.nonRegulated)}
                                                                    onChange={e => onTicketChange("nonRegulated", e.target.checked)}
                                                                />
                                                            </div>
                                                        </Col>
                                                        <Col xxl={2} md={6}>
                                                            <div style={{visibility: (fboState && fboState.ticket.nonRegulated) ? "" : "hidden"}} >
                                                                <Label className="form-label">No of Product Certificates</Label>
                                                                <input className="form-control"
                                                                    value={(fboState && fboState.ticket.nonRegulatedCount) || 0}
                                                                    onChange={e => onTicketChange("nonRegulatedCount", e.target.value)}
                                                                />
                                                            </div>
                                                        </Col>
                                                    </Fragment>
                                                    }
                                                    <Col xxl={12} md={12} className="d-none">
                                                        <Label className="form-label">Technical Regulation Name</Label>
                                                        <Typeahead
                                                            id="fldTechnicalRegulation"
                                                            labelKey="description"
                                                            onChange={onTechnicalRegulationChange}
                                                            options={technicalRegulationList}
                                                            placeholder="Select Technical Regulation..."
                                                            selected={selectedTechnicalRegulation}
                                                        />
                                                    </Col>
                                                    {fboState && fboState.ticket.serviceId === "2" &&
                                                    <Fragment>
                                                        <Col xxl={3} md={6}>
                                                            <Label className="form-label">PCOC Number(s)</Label>
                                                            {fboState && fboState.pcocNumbers.map(function(item, index){
                                                                return(
                                                                    <div className="d-flex mb-1" key={index}>
                                                                        <div className="flex-grow-1">
                                                                            <input className="form-control"
                                                                                value={(fboState && fboState.pcocNumbers[index]) || ""}
                                                                                onChange={e => onPcocNumberChange(index, e.target.value)}
                                                                            />
                                                                        </div>
                                                                        <div className="flex-shrink-0" style={{visibility: (index === 0 ? "" : "hidden")}}>
                                                                            <button type="button" className="btn btn-primary btn-sm btn-icon rounded-pill ms-1 mt-1" title="Add"
                                                                                onClick={() => onPcocNumberAdd(index)}
                                                                            >
                                                                                <i className="ri-add-line" />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })}
                                                        </Col>
                                                    </Fragment>
                                                    }                                                  
                                                </Row>
                                            </div>
                                            <div className="d-flex align-items-start gap-3 mt-4">
                                                <button
                                                    disabled={loading}
                                                    type="button"
                                                    className="btn btn-link text-decoration-none btn-label previestab"
                                                    onClick={() => {
                                                        toggleTab(activeTab - 1, 0);
                                                    }}
                                                >
                                                    <i className="ri-arrow-left-line label-icon align-middle fs-16 me-2"></i>{" "}
                                                    Back
                                                </button>
                                                <button
                                                    disabled={loading}
                                                    type="button"
                                                    className="btn btn-primary btn-label right ms-auto nexttab nexttab"
                                                    onClick={() => onSaveTicket()}
                                                >
                                                    <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                                                    Submit
                                                </button>
                                            </div>
                                        </TabPane>
                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default TicketAddScreen;