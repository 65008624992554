import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

//import images
import avatar1 from "../../assets/images/users/avatar-1.jpg";
import CardLoader from "../../components/CardLoader";

import { useDispatch, useSelector } from "react-redux";
import * as loginActions from "../../actions/LoginActions";
import * as commonActions from "../../actions/CommonActions";

const ProfileDropdown = () => {
    const dispatch = useDispatch();

    const user = useSelector((state) => state.login.user);
    const [userName, setUserName] = useState("");
    const [moduleName, setModuleName] = useState("");
    const [moduleList, setModuleList] = useState([]);
    const [companyName, setCompanyName] = useState("");
    const [companyList, setCompanyList] = useState([]);
    const [loadingLocal, setLoadingLocal] = useState(false);

    useEffect(() => {
        if (user) {
            setUserName(user.firstName + ' ' + user.lastName);
            
            if(user.defaultModule && user.modules) {
                setModuleList(user.modules);
                user.modules.forEach(module => {
                    if(module === user.defaultModule) {
                        setModuleName(module);
                    }
                });
            }

            if(user.defaultCompanyId && user.companies) {
                setCompanyList(user.companies);
                user.companies.forEach(company => {
                    if(company.id === user.defaultCompanyId) {
                        setCompanyName(company.name);
                    }
                });
            }
        }
    }, [user]);

    //Dropdown Toggle
    const [isProfileDropdown, setIsProfileDropdown] = useState(false);
    const toggleProfileDropdown = () => {
        setIsProfileDropdown(!isProfileDropdown);
    };

    const onChangeModuleClick = async (moduleName) => {
        if(module != moduleName) {
            setLoadingLocal(true);

            var data = await loginActions.changeCompanyAndModule(user.defaultCompanyId, moduleName);
            if(data.success) {
                window.location = "/";
            }
            else {
                dispatch(commonActions.setErrorMessage(data.error));
            }

            setLoadingLocal(false);
        }
    };

    const onChangeCompanyClick = async (company) => {
        if(company.name != companyName) {
            setLoadingLocal(true);

            var data = await loginActions.changeCompanyAndModule(company.id, user.defaultModule);
            if(data.success) {
                window.location = "/";
            }
            else {
                dispatch(commonActions.setErrorMessage(data.error));
            }

            setLoadingLocal(false);
        }
    };

    return (
        <React.Fragment>
            <CardLoader loading={loadingLocal} />
            <Dropdown isOpen={isProfileDropdown} toggle={toggleProfileDropdown} className="ms-sm-3 header-item topbar-user">
                <DropdownToggle tag="button" type="button" className="btn shadow-none">
                    <span className="d-flex align-items-center">
                        <img className="rounded-circle header-profile-user" src={avatar1}
                            alt="Header Avatar" />
                        <span className="text-start ms-xl-2">
                            <span className="d-none d-xl-inline-block ms-1 fw-medium user-name-text">{userName} ({moduleName}) ({companyName})</span>
                        </span>
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu-end">
                    <h6 className="dropdown-header">Welcome {userName}!</h6>
                    <DropdownItem divider />
                    <h5 className="dropdown-header text-muted">Modules</h5>
                    {moduleList.map(function(module, index){
                        return(
                            <DropdownItem key={index} onClick={() => onChangeModuleClick(module)}>
                                {module === moduleName && <i className="mdi mdi-account-check text-muted fs-16 align-middle me-1"></i>}
                                {module != moduleName && <i className="mdi mdi-account-switch text-muted fs-16 align-middle me-1"></i>}
                                <span className="align-middle">{module}</span>
                            </DropdownItem>
                        );
                    })}
                    <DropdownItem divider />
                    <h5 className="dropdown-header text-muted">Companies</h5>
                    {companyList.map(function(company, index){
                        return(
                            <DropdownItem key={index} onClick={() => onChangeCompanyClick(company)}>
                                {company.name === companyName && <i className="mdi mdi-account-check text-muted fs-16 align-middle me-1"></i>}
                                {company.name != companyName && <i className="mdi mdi-account-switch text-muted fs-16 align-middle me-1"></i>}
                                <span className="align-middle">{company.name}</span>
                            </DropdownItem>
                        );
                    })}
                    <DropdownItem divider />
                    <DropdownItem href={process.env.PUBLIC_URL + "/profile"}>
                        <i className="mdi mdi-account-circle text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle">Profile</span>
                    </DropdownItem>
                    <DropdownItem href={process.env.PUBLIC_URL + "/logout"}><i
                        className="mdi mdi-logout text-muted fs-16 align-middle me-1"></i>
                        <span className="align-middle" data-key="t-logout">Logout</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>
        </React.Fragment>
    );
};

export default ProfileDropdown;