import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Col, Container, Row, Label } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import ActionList from "../components/ActionList";
import Lookup from "../components/Lookup";
import { Highlighter, Menu, MenuItem, } from 'react-bootstrap-typeahead';
import { api } from "../utils/index";

import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../actions/UserActions";

const UserEditScreen  = (props) => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.user.loadingFBO);
    const fbo = useSelector((state) => state.user.fbo);
    const user = useSelector((state) => state.login.user);

    const [fboState, setFBOState] = React.useState(fbo);
    const [isDirty, setIsDirty] = React.useState(false);
    const [refreshAction, setRefreshAction] = React.useState(false);
    const [selectedReportTo, setSelectedReportTo] = React.useState([]);
    const [selectedFile, setSelectedFile] = React.useState(null);
    const [selectedFileCount, setSelectedFileCount] = React.useState(1);

    React.useEffect(() => {
	    dispatch(userActions.loadUser(id, navigate));
    }, [id]);

    React.useEffect(() => {
        setFBOState(fbo);
        setIsDirty(false);
        setRefreshAction(!refreshAction);
        setSelectedReportTo([]);
        
        if(fbo) {
            reset(fbo.userProfile);

            if(fbo.reportTo != null) {
                setSelectedReportTo([fbo.reportTo]);
            }
        }
        
        clearErrors();
    }, [fbo]);

    const reloadAction = () => {
        setRefreshAction(!refreshAction);
    }

    const onUserProfileChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value) {
            copy_fboState.userProfile[name] = value;
        }
        else if(value === false) {
            copy_fboState.userProfile[name] = false;
        }
        else {
            copy_fboState.userProfile[name] = null;
        }
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onUserRoleChange = (roleName, value) => {
        var copy_fboState = createDeepClone(fboState);
        
        var index =  copy_fboState.roleList.findIndex(x => x.name === roleName);
        if(index !== -1) {
            copy_fboState.roleList[index].isSelected = value;
            setFBOState(copy_fboState);
            setIsDirty(true);
        }
    }

    const onUserCompanyChange = (index, value) => {
        var copy_fboState = createDeepClone(fboState);
        copy_fboState.companyList[index].isSelected = value;
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onUserModuleChange = (index, value) => {
        var copy_fboState = createDeepClone(fboState);
        copy_fboState.moduleList[index].isSelected = value;
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onTicketStatusOptionChange = (index, value) => {
        var copy_fboState = createDeepClone(fboState);
        copy_fboState.ticketStatusList[index].isSelected = value;
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onIsoRequestStatusOptionChange = (index, value) => {
        var copy_fboState = createDeepClone(fboState);
        copy_fboState.isoRequestStatusList[index].isSelected = value;
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onFileChange = (event) => {
        if(event.target.files.length > 0) {
            setSelectedFile(event.target.files[0]);
        }

        setIsDirty(true);
    }

    const onSaveUserProfile = async() => {
        const valid = await trigger();
        if(valid) {
            const formData = new FormData();
            if(selectedFile) {
                formData.append("file", selectedFile, selectedFile.name);
            }
            formData.append("fboJson", JSON.stringify(fboState));

            dispatch(userActions.saveUser(formData, fboState.isAddMode, navigate, fboState.userProfile.id === user.id));
            
            setSelectedFile(null);
            setSelectedFileCount(selectedFileCount + 1);
        }
    }

    const onCancelUserProfile = () => {
        setFBOState(fbo);

        if(fbo.reportTo != null) {
            setSelectedReportTo([fbo.reportTo]);
        }
        else {
            setSelectedReportTo([]);
        }

        setIsDirty(false);
        clearErrors();
    }

    const ReportToRenderer = (results, menuProps, props) => (
        <Menu id="menuReportToLookup">
            <div className="container">
                <div className="row font-weight-bold border-bottom">
                    <div className="col">Name</div>
                    <div className="col">Email</div>
                    <div className="col">Employee ID</div>
                </div>
            </div>
            {results.length == 0 && <div style={{padding:5}}>No Macthes Found...</div>}
            {results.map((result, idx) => (
                <MenuItem
                    key={idx}
                    option={result}
                    position={idx}>
                    <div className="row border-bottom">
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.fullName || ''}
                            </Highlighter>
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.email || ''}
                            </Highlighter>
                        </div>
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.employeeId || ''}
                            </Highlighter>
                        </div>
                    </div>
                </MenuItem>
            ))}
        </Menu>
    )

    const onReportToChange = (item) => {
        setSelectedReportTo(item);
        
        if(item.length > 0) {
            onUserProfileChange("reportTo", item[0].id.toString());
        }
        else {
            onUserProfileChange("reportTo", null);
        }
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={fboState && fboState.userProfile.id > 0 ? "Edit User" : "Add User"} />
                    <Row>
                        <Col lg={fboState && fboState.userProfile.id > 0 ? 9 : 12}>
                            <CardLoader loading={loading} />

                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    
                                    <h5 className="card-title mb-0 flex-grow-1">User</h5>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-2">
                                            <button className="btn btn-primary btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onSaveUserProfile}
                                            >
                                                <i className="ri-save-line label-icon align-bottom"></i> Save User
                                            </button>
                                            <button className="btn btn-danger btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onCancelUserProfile}
                                            >
                                                <i className="ri-close-line label-icon align-bottom"></i> Cancel
                                            </button>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">First Name<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.userProfile.firstName) || ""}
                                                {...register("firstName", { required: true })}
                                                onChange={e => onUserProfileChange("firstName", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Last Name<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.userProfile.lastName) || ""}
                                                {...register("lastName", { required: true })}
                                                onChange={e => onUserProfileChange("lastName", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Email<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.email ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.userProfile.email) || ""}
                                                {...register("email", { required: true })}
                                                onChange={e => onUserProfileChange("email", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Employee ID<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.employeeId ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.userProfile.employeeId) || ""}
                                                {...register("employeeId", { required: true })}
                                                onChange={e => onUserProfileChange("employeeId", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Telephone Number</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.userProfile.telephoneNumber) || ""}
                                                onChange={e => onUserProfileChange("telephoneNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Mobile Number</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.userProfile.mobileNumber) || ""}
                                                onChange={e => onUserProfileChange("mobileNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Reports To</Label>
                                            <Lookup
                                                clearButton 
                                                allowNew ={false}
                                                id ="fldReportToLookup" 
                                                url = "GetUserLookup"
                                                defaultSearch={(fboState && fboState.userProfile.reportTo) || ""}
                                                placeholder= "Select Reports To..."
                                                labelKey= {option => `${option.fullName}`}
                                                selected = {selectedReportTo}
                                                onChange = {(item) => onReportToChange(item)}
                                                renderMenu = {ReportToRenderer}
                                            /> 
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Default Company<span className="required">*</span></Label>
                                            <select className={`form-select ${errors.defaultCompanyId ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.userProfile.defaultCompanyId) || 0}
                                                {...register("defaultCompanyId", { required: true })}
                                                onChange={e => onUserProfileChange("defaultCompanyId", e.target.value)}
                                            >
                                                <option value={""}>...</option>
                                                {fboState && fboState.companyList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.id}>{item.name}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Default Module<span className="required">*</span></Label>
                                            <select className={`form-select ${errors.defaultModule ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.userProfile.defaultModule) || ""}
                                                {...register("defaultModule", { required: true })}
                                                onChange={e => onUserProfileChange("defaultModule", e.target.value)}
                                            >
                                                <option value={""}>...</option>
                                                {fboState && fboState.moduleList.map(function(item, index){
                                                    return (
                                                        <option key={index} value={item.code}>{item.description}</option>
                                                    );
                                                })}
                                            </select>
                                        </Col>
                                        {fboState && fboState.userProfile.id === 0 &&
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Password<span className="required">*</span></Label>
                                            <input type="password" className={`form-control ${errors.newPassword ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.userProfile.newPassword) || ""}
                                                {...register("newPassword", { required: true })}
                                                onChange={e => onUserProfileChange("newPassword", e.target.value)}
                                            />
                                        </Col>
                                        }
                                        {fboState && fboState.userProfile.id > 0 &&
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Password</Label>
                                            <input type="password" className="form-control"
                                                value={(fboState && fboState.userProfile.newPassword) || ""}
                                                onChange={e => onUserProfileChange("newPassword", e.target.value)}
                                            />
                                        </Col>
                                        }
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Active</Label>
                                            <select className="form-select"
                                                value={(fboState && fboState.userProfile.active) || false}
                                                onChange={e => onUserProfileChange("active", e.target.value == "true" ? true : false)}
                                            >
                                                <option value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </select>
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Show Notifications</Label>
                                            <select className="form-select"
                                                value={(fboState && fboState.userProfile.enableNotification) || false}
                                                onChange={e => onUserProfileChange("enableNotification", e.target.value == "true" ? true : false)}
                                            >
                                                <option value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </select>
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Region</Label>
                                            <select className="form-select"
                                                value={(fboState && fboState.userProfile.region) || ""}
                                                onChange={e => onUserProfileChange("region", e.target.value)}
                                            >
                                                <option value="">...</option>
                                                <option value="Asia">Asia</option>
                                                <option value="Europe">Europe</option>
                                                <option value="Middle East and North Africa">Middle East and North Africa</option>
                                            </select>
                                        </Col>
                                        {fboState && fboState.userProfile.id > 0 &&
                                        <Col xxl={8} md={6}>
                                            <Label className="form-label">Select Signature File</Label>
                                            <input className="form-control" type="file" accept="image/*" multiple={false} key={selectedFileCount} onChange={onFileChange} />
                                        </Col>
                                        }
                                        {fboState && fboState.userProfile.id > 0 && fboState.userProfile.signatureFileName &&
                                        <Col xxl={4} md={6}>
                                            <Card className="card-light">
                                                <CardHeader style={{padding:7}}>
                                                    <button type="button" className="btn btn-danger btn-icon rounded-pill float-end fs-11"
                                                        onClick={() => onUserProfileChange("signatureFileName", "")}  
                                                    >
                                                        <i className="ri-delete-bin-2-line"></i>
                                                    </button>
                                                    <h6 className="card-title mb-0" style={{padding:7}}>Signature</h6>
                                                </CardHeader>
                                                <CardBody>
                                                    <img className="img-fluid" style={{maxWidth:"100%", maxHeight: "300px"}} src={`${api}Document/DownloadDocumentByName?fileName=${fboState.userProfile.signatureFileName}`} alt="Not Found" />
                                                </CardBody>
                                            </Card>
                                        </Col>
                                        }
                                    </Row>
                                </CardBody>
                            </Card>

                            <Row>
                                <Col xxl={4} md={4} className="pe-0">
                                    <Card className="mb-0 h-100">
                                        <CardHeader className="border-0 d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">Common Roles</h5>
                                        </CardHeader>
                                        <CardBody className="border border-dashed border-end-0 border-start-0">
                                            {fboState && fboState.roleList.filter(x => x.name !== "CRM User" && x.moduleName === "Common").map(function(item, index){
                                                return (
                                                    <div className="form-check mb-1" key={index}>
                                                        <input type="checkbox" className="form-check-input"
                                                            checked={item.isSelected}
                                                            onChange={e => onUserRoleChange(item.name, e.target.checked)}
                                                        />
                                                        <Label className="mt-0">{item.name}</Label>
                                                    </div>
                                                );
                                            })}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xxl={4} md={4} className="ps-0 pe-0">
                                    <Card className="mb-0 h-100">
                                        <CardHeader className="border-0 d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">Ticketing Roles</h5>
                                        </CardHeader>
                                        <CardBody className="border border-dashed border-end-0 border-start-0">
                                            {fboState && fboState.roleList.filter(x => x.name !== "CRM User" && x.moduleName === "Ticketing").map(function(item, index){
                                                return (
                                                    <div className="form-check mb-1" key={index}>
                                                        <input type="checkbox" className="form-check-input"
                                                            checked={item.isSelected}
                                                            onChange={e => onUserRoleChange(item.name, e.target.checked)}
                                                        />
                                                        <Label className="mt-0">{item.name}</Label>
                                                    </div>
                                                );
                                            })}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xxl={4} md={4} className="ps-0">
                                    <Card className="mb-0 h-100">
                                        <CardHeader className="border-0 d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">ISO Roles</h5>
                                        </CardHeader>
                                        <CardBody className="border border-dashed border-end-0 border-start-0">
                                            {fboState && fboState.roleList.filter(x => x.name !== "CRM User" && x.moduleName === "ISO").map(function(item, index){
                                                return (
                                                    <div className="form-check mb-1" key={index}>
                                                        <input type="checkbox" className="form-check-input"
                                                            checked={item.isSelected}
                                                            onChange={e => onUserRoleChange(item.name, e.target.checked)}
                                                        />
                                                        <Label className="mt-0">{item.name}</Label>
                                                    </div>
                                                );
                                            })}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row>
                                <Col xxl={6} md={6} className="pe-0">
                                    <Card className="mb-0 h-100">
                                        <CardHeader className="border-0 d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">Assigned Companies</h5>
                                        </CardHeader>
                                        <CardBody className="border border-dashed border-end-0 border-start-0">
                                            {fboState && fboState.companyList.map(function(item, index){
                                                return (
                                                    <div className="form-check mb-1" key={index}>
                                                        <input type="checkbox" className="form-check-input"
                                                            checked={item.isSelected}
                                                            onChange={e => onUserCompanyChange(index, e.target.checked)}
                                                        />
                                                        <Label className="mt-0">{item.name}</Label>
                                                    </div>
                                                );
                                            })}
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xxl={6} md={6} className="ps-0">
                                    <Card className="mb-0 h-100">
                                        <CardHeader className="border-0 d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">Assigned Modules</h5>
                                        </CardHeader>
                                        <CardBody className="border border-dashed border-end-0 border-start-0">
                                            {fboState && fboState.moduleList.map(function(item, index){
                                                return (
                                                    <div className="form-check mb-1" key={index}>
                                                        <input type="checkbox" className="form-check-input"
                                                            checked={item.isSelected}
                                                            onChange={e => onUserModuleChange(index, e.target.checked)}
                                                        />
                                                        <Label className="mt-0">{item.description}</Label>
                                                    </div>
                                                );
                                            })}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                            <Row className="mb-5">
                                <Col xxl={7} md={7} className="pe-0">
                                    <Card className="h-100">
                                        <CardHeader className="border-0 d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">Ticket Status <span className="text-primary" style={{fontSize:12}}><i>(Only selected would be visible on dashboard.)</i></span></h5>
                                        </CardHeader>
                                        <CardBody className="border border-dashed border-end-0 border-start-0">
                                            <Row className="gy-2">
                                                <Col xxl={4} md={4}>
                                                    <h5>Common</h5>
                                                    {fboState && fboState.ticketStatusList.filter(x => x.groupName === "Common").map(function(item, index){
                                                        return (
                                                            <div className="form-check mb-1" key={index}>
                                                                <input type="checkbox" className="form-check-input"
                                                                    checked={item.isSelected}
                                                                    onChange={e => onTicketStatusOptionChange(index, e.target.checked)}
                                                                />
                                                                <Label className="mt-0">{item.code}</Label>
                                                            </div>
                                                        );
                                                    })}
                                                </Col>
                                                <Col xxl={4} md={4}>
                                                    <h5>Factory Audit</h5>
                                                    {fboState && fboState.ticketStatusList.filter(x => x.groupName === "FA").map(function(item, index){
                                                        return (
                                                            <div className="form-check mb-1" key={index}>
                                                                <input type="checkbox" className="form-check-input"
                                                                    checked={item.isSelected}
                                                                    onChange={e => onTicketStatusOptionChange(index, e.target.checked)}
                                                                />
                                                                <Label className="mt-0">{item.code}</Label>
                                                            </div>
                                                        );
                                                    })}
                                                </Col>
                                                <Col xxl={4} md={4}>
                                                    <h5>SFDA</h5>
                                                    {fboState && fboState.ticketStatusList.filter(x => x.groupName === "SFDA").map(function(item, index){
                                                        return (
                                                            <div className="form-check mb-1" key={index}>
                                                                <input type="checkbox" className="form-check-input"
                                                                    checked={item.isSelected}
                                                                    onChange={e => onTicketStatusOptionChange(index, e.target.checked)}
                                                                />
                                                                <Label className="mt-0">{item.code}</Label>
                                                            </div>
                                                        );
                                                    })}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xxl={5} md={5} className="ps-0">
                                    <Card className="h-100">
                                        <CardHeader className="border-0 d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">ISO Ticket Status <span className="text-primary" style={{fontSize:12}}><i>(Only selected would be visible on dashboard.)</i></span></h5>
                                        </CardHeader>
                                        <CardBody className="border border-dashed border-end-0 border-start-0">
                                            {fboState && fboState.isoRequestStatusList.map(function(item, index){
                                                return (
                                                    <div className="form-check mb-1" key={index}>
                                                        <input type="checkbox" className="form-check-input"
                                                            checked={item.isSelected}
                                                            onChange={e => onIsoRequestStatusOptionChange(index, e.target.checked)}
                                                        />
                                                        <Label className="mt-0">{item.code}</Label>
                                                    </div>
                                                );
                                            })}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                        
                        {fboState && fboState.userProfile.id > 0 &&
                        <Col lg={3}>
                            <ActionList id={fboState.userProfile.id} name={"USER"} refreshAction={refreshAction} />
                        </Col>
                        }
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default UserEditScreen;