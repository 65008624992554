import React, { Fragment, useState } from "react";
import { Card, CardBody, CardHeader, Col, Label, Row, Table, Spinner, Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import Flatpickr from "react-flatpickr";
import dateFormat from "dateformat";

import { createDeepClone } from "../utils/index";

import ConfirmModal from "./ConfirmModal";
import CardLoader from "./CardLoader";
import MyDateRenderer from "./MyDateRenderer";
import { getFileTitle } from "../utils/index";

import { useDispatch, useSelector } from "react-redux";
import * as documentActions from "../actions/DocumentActions";
import * as commonActions from "../actions/CommonActions";

const ServiceDocumentGrid = (props) => {
    const dispatch = useDispatch();

    const [showModal, setShowModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState([]);
    
    const [deleteId, setDeleteId] = React.useState(0);
    const [showDeleteModal, setShowDeleteModal] = React.useState(false);

    const downloading = useSelector((state) => state.document.downloading);
    const documentTypeList = useSelector((state) => state.login.data.documentTypeList);

    const [loading, setLoading] = React.useState(false);
    const [saving, setSaving] = React.useState(false);
    const [showExpiryDateMessage, setShowExpiryDateMessage] = React.useState(false);
    const [showCertificateTypeMessage, setShowCertificateTypeMessage] = React.useState(false);
    const [fboState, setFBOState] = React.useState(null);
    const [documentList, setDocumentList] = React.useState([]);
    const [gridCollapsed, setGridCollapsed] = React.useState(props.collapsed === "false" ? false : true);

    React.useEffect(() => {
        async function fetchData() {
            setLoading(true);
        
            var data = await documentActions.loadServiceDocument(props.serviceId, props.refId, props.refName, props.parentId, props.parentName, props.isProduct);
            if(data.error) {
                dispatch(commonActions.setErrorMessage(data.error));
            }
            else {
                setFBOState(data.fbo);
                setDocumentList(data.list);
            }
    
            setLoading(false);
        }

        fetchData();
    }, [props.serviceId, props.refId, props.refName, props.parentId, props.parentName, props.isProduct]);

    const getFormattedDate = (value) => {
        if(value) {
            return dateFormat(value, "mm/dd/yyyy");
        }
        return "";
    }

    const getDateWithoutTimezone = (value) => {
        if(value) {
            const tzoffset = value.getTimezoneOffset() * 60000;
            const withoutTimezone = new Date(value.valueOf() - tzoffset).toISOString().slice(0, -1);
            return withoutTimezone;
        }
        return "";
    }

    const onDocumentChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);

        if(value) {
            if(name === "validDate") {
                copy_fboState[name] = getDateWithoutTimezone(value);
            }
            else {
                copy_fboState[name] = value;
            }
        }
        else if(value === false) {
            copy_fboState[name] = false;
        }
        else {
            copy_fboState[name] = null;
        }
        
        setFBOState(copy_fboState);
        setShowExpiryDateMessage(false);
        setShowCertificateTypeMessage(false);
    }

    const onFileChange = (event) => {
        if(event.target.files.length > 0) {
            var files = [...event.target.files];
            var copy_selectedFile = createDeepClone(selectedFile);
            
            if(props.serviceId === 5 && fboState.documentType === "TR") {
                files.map(function(item, index) {
                    copy_selectedFile.push({file: item, name: item.name, title: "", titleValid: false});
                });
            }
            else {
                files.map(function(item, index) {
                    copy_selectedFile.push({file: item, name: item.name, title: getFileTitle(item), titleValid: true});
                });
            }

            setSelectedFile(copy_selectedFile);
        }
    }

    const onFileTitleChange = (index, value) => {
        var copy_selectedFile = createDeepClone(selectedFile);
        if(value) {
            copy_selectedFile[index].title = value;
            copy_selectedFile[index].titleValid = true;
        }
        else {
            copy_selectedFile[index].title = null;
            copy_selectedFile[index].titleValid = false;
        }
        
        setSelectedFile(copy_selectedFile);
    }

    const onFileDelete = (index) => {
        var copy_selectedFile = createDeepClone(selectedFile);
        copy_selectedFile.splice(index, 1);
        setSelectedFile(copy_selectedFile);
    }

    const showModalDialog = (documentType) => {
        onDocumentChange("documentType", documentType);

        setSelectedFile([]);
                
        setShowModal(true);
    }

    const hideModalDialog = () => {
        setShowModal(false);
    }

    

    const onSaveDocument = async () => {
        if(selectedFile && selectedFile.length > 0) {
            var valid = true;

            if(fboState.documentType === "FA" && !fboState.validDate) {
                valid = false;
                setShowExpiryDateMessage(true);
            }
            else if(props.serviceId === 5 && fboState.documentType === "CR" && !fboState.certificateType) {
                valid = false;
                setShowCertificateTypeMessage(true);
            }
            else {
                selectedFile.map(function(item, index){
                    if(!item.titleValid) {
                        valid = false;
                    }
                });
            }

            if(valid) {
                const formData = new FormData();
                
                selectedFile.map(function(item, index){
                    formData.append("file", item.file, item.name);
                    formData.append("title", item.title ? item.title : item.name);
                });

                formData.append("document", JSON.stringify(fboState));
                formData.append("serviceId", props.serviceId);
                formData.append("isProduct", props.isProduct);
                
                setSaving(true);

                var data = await documentActions.saveServiceDocument(formData);
                if(data.error) {
                    dispatch(commonActions.setErrorMessage(data.error));
                }
                else {
                    setFBOState(data.fbo);
                    setDocumentList(data.list);

                    hideModalDialog();

                    setSelectedFile([]);
                    if(props.onChange) {
                        props.onChange(props.isProduct);
                    }

                    if(props.isProduct) {
                        dispatch(commonActions.setSuccessMessage("Product Document(s) Added Successfully!"))
                    }
                    else {
                        dispatch(commonActions.setSuccessMessage("Document(s) Added Successfully!"))
                    }
                }

                setSaving(false);
            }
        }
        else {
            dispatch(commonActions.setErrorMessage("No file is selected."));
        }
    }

    const onDeleteClick = (id) => {
        setDeleteId(id);
        setShowDeleteModal(true);
    }

    const onDeleteConfirm = async () => {
        setShowDeleteModal(false);

        if(deleteId > 0) {
            setLoading(true);
        
            var data = await documentActions.deleteServiceDocument(deleteId, props.serviceId, props.refId, props.refName, props.parentId, props.parentName, props.isProduct);
            if(data.error) {
                dispatch(commonActions.setErrorMessage(data.error));
            }
            else {
                setFBOState(data.fbo);
                setDocumentList(data.list);

                if(props.onChange) {
                    props.onChange(props.isProduct);
                }

                if(props.isProduct) {
                    dispatch(commonActions.setSuccessMessage("Product Document Delete Successfully!"))
                }
                else {
                    dispatch(commonActions.setSuccessMessage("Document Delete Successfully!"))
                }
            }
    
            setLoading(false);
        }
    }

    const onDownloadServiceTemplate = (templateName) => {
        dispatch(documentActions.downloadServiceTemplate(templateName));
    };

    const onDownloadServiceDocument = (id, documentName) => {
        dispatch(documentActions.downloadServiceDocument(id, documentName));
    };

    return (
        <Fragment>
            <Card className={`${props.isProduct ? "mb-3" : "mb-3"}`}>
                <CardHeader className="border-0 d-flex align-items-center">
                    <CardLoader loading={loading || downloading} />
                    <h5 className="card-title mb-0 flex-grow-1 cursor-pointer" onClick={() => setGridCollapsed(!gridCollapsed)}>
                        {gridCollapsed && <i className="ri-add-box-line align-bottom text-primary"></i>}
                        {!gridCollapsed && <i className="ri-checkbox-indeterminate-line align-bottom text-primary"></i>}
                        {props.isProduct ? "Product " : ""}Attachments / Documents ({gridCollapsed ? "Expand" : "Collapse"})
                    </h5>
                </CardHeader>
                <CardBody className="border border-dashed border-end-0 border-start-0">
                    <Collapse isOpen={!gridCollapsed} className="collapse">
                        {documentList.map(function(item, index){
                            return(
                                <Card className={`${props.isProduct ? "card-light" : "card-light"}`} key={index}>
                                    <CardHeader className="d-flex">
                                        <div className="flex-grow-1">
                                            <h4 className="card-title mb-2">{item.name}</h4>
                                            <h6 className="card-subtitle font-14 text-muted">{item.instruction}</h6>
                                        </div>
                                        {item.templateName &&
                                            <button type="button" className="btn btn-secondary btn-icon float-end rounded-pill ms-1" title="Download Template"
                                                onClick={() => onDownloadServiceTemplate(item.templateName)}
                                            >
                                                <i className="ri-download-line" />
                                            </button>
                                        }
                                        {props.canChange && item.code !== "CR" &&
                                        <button type="button" className="btn btn-primary btn-icon float-end rounded-pill ms-1" title="Upload Document"
                                            onClick={() => showModalDialog(item.code)}
                                        >
                                            <i className="ri-upload-line" />
                                        </button>
                                        }
                                        {props.canChangeCertificate && item.code === "CR" &&
                                        <button type="button" className="btn btn-primary btn-icon float-end rounded-pill ms-1" title="Upload Document"
                                            onClick={() => showModalDialog(item.code)}
                                        >
                                            <i className="ri-upload-line" />
                                        </button>
                                        }
                                    </CardHeader>
                                    <CardBody className="card-text">
                                        {item.parentDocumentList.length > 0 &&
                                            <Fragment>
                                                <Row className="gy-2 pt-1"><h6>Container Documents</h6></Row>
                                                {item.parentDocumentList.map(function(document, index){
                                                    return(
                                                        <Row className="gy-2 pt-1 font-14" key={index}>
                                                            <Col xxl={3} md={6}>{document.title}</Col>
                                                            <Col xxl={3} md={6}>{document.name}</Col>
                                                            <Col xxl={3} md={6}><MyDateRenderer value={document.dateAdded} /></Col>
                                                            <Col xxl={3} md={6}>
                                                                <button type="button" className="btn btn-primary btn-sm me-1" title="Download Document"
                                                                    onClick={() => onDownloadServiceDocument(document.id, document.name)}
                                                                >
                                                                    <i className="ri-download-line" /> Download
                                                                </button>
                                                            </Col>
                                                        </Row>
                                                    );
                                                })}
                                                <Row className="gy-2 pt-3"><hr /></Row>
                                            </Fragment>
                                        }
                                        {item.documentList.length == 0 && <div className="font-14">No Document Uploaded</div>}
                                        {item.documentList.map(function(document, index){
                                            return(
                                                <Row className="gy-2 pt-1 font-14" key={index}>
                                                    <Col xxl={3} md={6}>
                                                        {document.title}
                                                        {document.validDate && <Fragment><br /><span className="badge bg-danger"><MyDateRenderer value={document.validDate} format={"mm/dd/yyyy"} /></span></Fragment>}
                                                        {document.certificateType && <Fragment><br /><span className="badge bg-danger">{document.certificateType}</span></Fragment>}
                                                    </Col>
                                                    <Col xxl={3} md={6}>{document.name}</Col>
                                                    <Col xxl={3} md={6}><MyDateRenderer value={document.dateAdded} /></Col>
                                                    <Col xxl={3} md={6}>
                                                        <button type="button" className="btn btn-primary btn-sm me-1" title="Download Document"
                                                            onClick={() => onDownloadServiceDocument(document.id, document.name)}
                                                        >
                                                            <i className="ri-download-line" /> Download
                                                        </button>
                                                        {props.canChange && item.code !== "CR" &&
                                                        <button type="button" className="btn btn-danger btn-sm me-1" title="Delete Document"
                                                            onClick={() => onDeleteClick(document.id)}
                                                        >
                                                            <i className="ri-delete-bin-2-line" /> Delete
                                                        </button>
                                                        }
                                                        {props.canChangeCertificate && item.code === "CR" &&
                                                        <button type="button" className="btn btn-danger btn-sm me-1" title="Delete Document"
                                                            onClick={() => onDeleteClick(document.id)}
                                                        >
                                                            <i className="ri-delete-bin-2-line" /> Delete
                                                        </button>
                                                        }
                                                    </Col>
                                                </Row>
                                            );
                                        })}
                                    </CardBody>
                                </Card>
                            );
                        })}
                    </Collapse>
                </CardBody>
            </Card>

            <Modal tabIndex="-1" size="xl" isOpen={showModal} toggle={hideModalDialog} centered={true}>
                <ModalHeader>
                    Upload Documents
                </ModalHeader>
                <ModalBody>
                    <CardLoader loading={saving} />
                    <Row className="gy-2">
                        <Col xxl={12} md={12}>
                            <label className="form-label">Document Type</label>
                            <select className="form-select" disabled={true}
                                value={(fboState && fboState.documentType) || ""}
                                onChange={e => onDocumentChange("documentType", e.target.value)}
                            >
                                <option value={""}>...</option>
                                {documentTypeList && documentTypeList.map(function(item, index){
                                    return (
                                        <option key={index} value={item.code}>{item.description}</option>
                                    );
                                })}
                            </select>
                        </Col>
                        {fboState && fboState.documentType === "FA" &&
                        <Col xxl={12} md={12}>
                            <label className="form-label">Expiry Date</label>
                            <Flatpickr className={`form-control ${showExpiryDateMessage ? "is-invalid" : ""}`}
                                value={(fboState && getFormattedDate(fboState.validDate)) || ""}
                                onChange={([date]) => onDocumentChange("validDate", date)}
                                options={{
                                    dateFormat: "m/d/Y"
                                }}
                            />
                        </Col>
                        }
                        {fboState && props.serviceId === 5 && fboState.documentType === "CR" &&
                        <Col xxl={12} md={12}>
                            <label className="form-label">Certificate Type</label>
                            <select className={`form-select ${showCertificateTypeMessage ? "is-invalid" : ""}`}
                                value={(fboState && fboState.certificateType) || ""}
                                onChange={e => onDocumentChange("certificateType", e.target.value)}
                            >
                                <option value={""}>...</option>
                                <option value={"SCOC"}>SCOC</option>
                                <option value={"NOCOC"}>NOCOC</option>
                            </select>
                        </Col>
                        }
                        <Col xxl={12} md={12}>
                            <label className="btn btn-primary btn-label mb-0 float-end">
                                <i className="ri-check-double-line label-icon align-bottom"></i> Choose File(s)
                                <input className="d-none" type="file" onChange={onFileChange} multiple={true} />
                            </label>
                        </Col>
                        <Col xxl={12} md={12}>
                            <Table className="table-hover table-bordered align-middle mb-0">
                                <thead className="table-primary">
                                    <tr>
                                        <th>Title</th>
                                        <th>File Name</th>
                                        <th>&nbsp;</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedFile.length === 0 && <tr><td colSpan={3}>No file(s) Selected</td></tr>}
                                    {selectedFile.map(function(item, index){
                                        return(
                                            <tr key={index}>
                                                <td>
                                                    <div className="col-sm-12">
                                                        <input className={`form-control ${item.titleValid ? "" : "is-invalid"}`}
                                                            placeholder={(props.serviceId === 5 && fboState.documentType === "TR") ? "Add Product Name here..." : "Add Title..."}
                                                            value={item.title}
                                                            onChange={e => onFileTitleChange(index, e.target.value)}
                                                        />
                                                    </div>
                                                </td>
                                                <td style={{verticalAlign:"middle"}}>
                                                    <div className="col-sm-12">
                                                        <strong>{item.name}</strong>
                                                    </div>
                                                </td>
                                                <td className="text-center">
                                                    <button type="button" className="btn btn-danger btn-icon rounded-pill ms-1" title="Remove Photo"
                                                        onClick={() => onFileDelete(index)}
                                                    >
                                                        <i className="ri-delete-bin-2-line"></i>
                                                    </button>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </Table>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <button type="button" className="btn btn-primary btn-label" onClick={onSaveDocument} disabled={loading || saving}>
                        <i className="ri-save-line label-icon align-bottom"></i> Save Document(s)
                    </button>
                    <button type="button" className="btn btn-danger btn-label" data-dismiss="modal" onClick={hideModalDialog} disabled={loading || saving}>
                        <i className="ri-close-line label-icon align-bottom"></i> Close
                    </button>
                </ModalFooter>
            </Modal>

            <ConfirmModal message={"Are you sure you want to delete the Document?"} show={showDeleteModal} onCloseClick={() => setShowDeleteModal(false)} onYesClick={onDeleteConfirm} />
        </Fragment>
    );
};

export default ServiceDocumentGrid;