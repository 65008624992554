import React from "react";

const CustomerCategoryLabel = (props) => {
    if(props.value) {
        return(
            <span title={props.value}>
                <span className="avatar-xxs img-thumbnail rounded-circle" style={{display:"inline-block", padding:"0"}}>
                    <span className="avatar-title text-uppercase border rounded-circle bg-primary">{props.value.substring(0,1)}</span>
                </span>
            </span>
        );
    }
    
    return "";
};

export default CustomerCategoryLabel;