import React, { Fragment } from "react";
import { useParams } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Spinner } from 'reactstrap';

import { api } from "../utils/index";

import CardLoader from "../components/CardLoader";

const IsoRequestCertificateView  = (props) => {
    const { id } = useParams();

    const [localLoading, setLocalLoading] = React.useState(true);

    React.useEffect(() => {
        setLocalLoading(true);  
    }, [id]);

    const onDocumentLoaded = () => {
        setLocalLoading(false);
    }
    
    return(
        <Fragment>
            <CardLoader loading={localLoading} />
            <Row className="gy-2">
                <iframe src={`${api}Document/DownloadIsoCertificateStream?id=${id}`} style={{display: "block", width:"100vw", height:"95vh", border: "none"}} onLoad={onDocumentLoaded} />
            </Row>
        </Fragment>
    );
}

export default IsoRequestCertificateView;