import React, { Fragment, useState } from "react";
import { NavLink } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Label, Input, Row, Table, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import { createDeepClone } from "../utils/index";

import CardLoader from "./CardLoader";
import MyDateRenderer from "./MyDateRenderer";
import ConfirmModal from "./ConfirmModal";

import { useDispatch, useSelector } from "react-redux";
import * as ticketActions from "../actions/TicketActions";

const RelatedTicketList = (props) => {
    const dispatch = useDispatch();

    const loading = useSelector((state) => state.ticket.loadingRelated);
    const data = useSelector((state) => state.ticket.relatedList);
    const user = useSelector((state) => state.login.user);
    
    React.useEffect(() => {
		dispatch(ticketActions.loadRelatedTicket(props.ticketId, props.serviceId, props.customerId));
    }, [props.ticketId, props.serviceId, props.customerId]);

    return (
        <Fragment>
            <Row className="gy-2">
                <CardLoader loading={loading} />
                <Col xxl={12} md={12}>
                    <Table className="table-hover table-bordered align-middle mb-0">
                        <thead className="table-primary">
                            <tr>
                                <th>Ticket #</th>
                                <th>Status</th>
                                {user.isLinkedToCrm && <th>CRM Invoice #</th>}
                                {user.isLinkedToCrm && <th>Tax Invoice #</th>}
                                {props.serviceId === 1 && <th>SASO Request #</th>}
                                {props.serviceId === 5 && <th>Faseh Request #</th>}
                                <th>Action Date</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data.length == 0 && <tr><td colSpan={7}>No Ticket(s)</td></tr>}
                            {data.map(function(item, index){
                                return(
                                    <tr key={index}>
                                        <td>
                                            <NavLink  to={`/request-edit/${item.id}`}>{item.requestNumber}</NavLink>
                                        </td>
                                        <td><span className="badge" style={{backgroundColor: item.statusColorCode}}>{item.statusName}</span></td>
                                        {user.isLinkedToCrm && <td>{item.crmInvoiceNumber}</td>}
                                        {user.isLinkedToCrm && <td>{item.taxInvoiceNumber}</td>}
                                        {props.serviceId === 1 && <td>{item.sasoRequestNumber}</td>}
                                        {props.serviceId === 5 && <td>{item.fasehRequestNumber}</td>}
                                        <td><MyDateRenderer value={item.lastActionDate} /></td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Fragment>
    );
};

export default RelatedTicketList;