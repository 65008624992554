import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Col, Container, Row, Label, Input, Spinner } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import ActionList from "../components/ActionList";

import { useDispatch, useSelector } from "react-redux";
import * as userActions from "../actions/UserActions";

const UserProfileScreen  = () => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.user.loadingProfile);
    const fbo = useSelector((state) => state.user.profile);

    const [fboState, setFBOState] = React.useState(fbo);
    const [isDirty, setIsDirty] = React.useState(false);
    const [refreshAction, setRefreshAction] = React.useState(false);

    React.useEffect(() => {
	    dispatch(userActions.loadUserProfile());
    }, []);

    React.useEffect(() => {
		setFBOState(fbo);
        setIsDirty(false);
        setRefreshAction(!refreshAction);

        reset(fbo);
        clearErrors();
    }, [fbo]);

    const reloadAction = () => {
        setRefreshAction(!refreshAction);
    }

    const onUserProfileChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);
        if(value) {
            copy_fboState[name] = value;
        }
        else if(value === false) {
            copy_fboState[name] = false;
        }
        else {
            copy_fboState[name] = null;
        }
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onTicketStatusOptionChange = (index, value) => {
        var copy_fboState = createDeepClone(fboState);
        copy_fboState.ticketStatusList[index].isSelected = value;
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onIsoRequestStatusOptionChange = (index, value) => {
        var copy_fboState = createDeepClone(fboState);
        copy_fboState.isoRequestStatusList[index].isSelected = value;
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onSaveUserProfile = async() => {
        const valid = await trigger();
        if(valid) {
            dispatch(userActions.saveUserProfile(fboState));
        }
    }

    const onCancelUserProfile = () => {
        setFBOState(fbo);
        setIsDirty(false);
        clearErrors();
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={fboState && fboState.id > 0 ? "Edit Profile" : "Add Profile"} />
                    <Row>
                        <Col lg={fboState && fboState.id > 0 ? 9 : 12}>
                            <CardLoader loading={loading} />

                            <Card className="mb-0">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Profile</h5>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-2">
                                            <button className="btn btn-primary"
                                                disabled={loading || !isDirty}
                                                onClick={onSaveUserProfile}
                                            >
                                                <i className="ri-save-line align-bottom"></i> Save Profile
                                            </button>
                                            <button className="btn btn-danger"
                                                disabled={loading || !isDirty}
                                                onClick={onCancelUserProfile}
                                            >
                                                <i className="ri-close-line align-bottom"></i> Cancel
                                            </button>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">First Name<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.firstName ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.firstName) || ""}
                                                {...register("firstName", { required: true })}
                                                onChange={e => onUserProfileChange("firstName", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Last Name<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.lastName ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.lastName) || ""}
                                                {...register("lastName", { required: true })}
                                                onChange={e => onUserProfileChange("lastName", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Email</Label>
                                            <input type="text" className="form-control" readOnly={true}
                                                value={(fboState && fboState.email) || ""}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Telephone Number</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.telephoneNumber) || ""}
                                                onChange={e => onUserProfileChange("telephoneNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Mobile Number</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.mobileNumber) || ""}
                                                onChange={e => onUserProfileChange("mobileNumber", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Show Notifications</Label>
                                            <select className="form-select"
                                                value={(fboState && fboState.enableNotification) || false}
                                                onChange={e => onUserProfileChange("enableNotification", e.target.value == "true" ? true : false)}
                                            >
                                                <option value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </select>
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Password</Label>
                                            <input type="password" className="form-control"
                                                value={(fboState && fboState.password) || ""}
                                                onChange={e => onUserProfileChange("password", e.target.value)}
                                            />
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            <Row className="mb-5">
                                <Col xxl={7} md={7} className="pe-0">
                                    <Card className="h-100">
                                        <CardHeader className="border-0 d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">Ticket Status <span className="text-primary" style={{fontSize:12}}><i>(Only selected would be visible on dashboard.)</i></span></h5>
                                        </CardHeader>
                                        <CardBody className="border border-dashed border-end-0 border-start-0">
                                            <Row className="gy-2">
                                                <Col xxl={4} md={4}>
                                                    <h5>Common</h5>
                                                    {fboState && fboState.ticketStatusList.filter(x => x.groupName === "Common").map(function(item, index){
                                                        return (
                                                            <div className="form-check mb-1" key={index}>
                                                                <input type="checkbox" className="form-check-input"
                                                                    checked={item.isSelected}
                                                                    onChange={e => onTicketStatusOptionChange(index, e.target.checked)}
                                                                />
                                                                <Label className="mt-0">{item.code}</Label>
                                                            </div>
                                                        );
                                                    })}
                                                </Col>
                                                <Col xxl={4} md={4}>
                                                    <h5>Factory Audit</h5>
                                                    {fboState && fboState.ticketStatusList.filter(x => x.groupName === "FA").map(function(item, index){
                                                        return (
                                                            <div className="form-check mb-1" key={index}>
                                                                <input type="checkbox" className="form-check-input"
                                                                    checked={item.isSelected}
                                                                    onChange={e => onTicketStatusOptionChange(index, e.target.checked)}
                                                                />
                                                                <Label className="mt-0">{item.code}</Label>
                                                            </div>
                                                        );
                                                    })}
                                                </Col>
                                                <Col xxl={4} md={4}>
                                                    <h5>SFDA</h5>
                                                    {fboState && fboState.ticketStatusList.filter(x => x.groupName === "SFDA").map(function(item, index){
                                                        return (
                                                            <div className="form-check mb-1" key={index}>
                                                                <input type="checkbox" className="form-check-input"
                                                                    checked={item.isSelected}
                                                                    onChange={e => onTicketStatusOptionChange(index, e.target.checked)}
                                                                />
                                                                <Label className="mt-0">{item.code}</Label>
                                                            </div>
                                                        );
                                                    })}
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xxl={5} md={5} className="ps-0">
                                    <Card className="h-100">
                                        <CardHeader className="border-0 d-flex align-items-center">
                                            <h5 className="card-title mb-0 flex-grow-1">ISO Ticket Status <span className="text-primary" style={{fontSize:12}}><i>(Only selected would be visible on dashboard.)</i></span></h5>
                                        </CardHeader>
                                        <CardBody className="border border-dashed border-end-0 border-start-0">
                                            {fboState && fboState.isoRequestStatusList.map(function(item, index){
                                                return (
                                                    <div className="form-check mb-1" key={index}>
                                                        <input type="checkbox" className="form-check-input"
                                                            checked={item.isSelected}
                                                            onChange={e => onIsoRequestStatusOptionChange(index, e.target.checked)}
                                                        />
                                                        <Label className="mt-0">{item.code}</Label>
                                                    </div>
                                                );
                                            })}
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>

                        {fboState && fboState.id > 0 &&
                        <Col lg={3}>
                            <ActionList id={fboState.id} name={"USER_PROFILE"} refreshAction={refreshAction} />
                        </Col>
                        }
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default UserProfileScreen;