import { SEARCH_PAGE_SIZE } from "../constants/CommonConstants";
import { TICKET_SEARCH_START_LOADING, TICKET_SEARCH_END_LOADING, TICKET_NOTIFY_START_LOADING, TICKET_NOTIFY_END_LOADING } from "../constants/TicketConstants";
import { TICKET_SEARCH_LOADED, TICKET_NOTIFY_LOADED, TICKET_SEARCH_REVW_LOADED, TICKET_SEARCH_SCOC_LOADED, TICKET_SEARCH_PCOC_LOADED, TICKET_SEARCH_FA_LOADED, TICKET_SEARCH_SOC_LOADED } from "../constants/TicketConstants";
import { SET_TICKET_FILTER, SET_TICKET_NOTIFY_FILTER, SET_TICKET_REVW_FILTER, SET_TICKET_SCOC_FILTER, SET_TICKET_PCOC_FILTER, SET_TICKET_FA_FILTER, SET_TICKET_SOC_FILTER } from "../constants/TicketConstants";
import { TICKET_TAG_START_LOADING, TICKET_TAG_END_LOADING, TICKET_TAG_LOADED } from "../constants/TicketConstants";
import { TICKET_START_LOADING, TICKET_END_LOADING, TICKET_LOADED } from "../constants/TicketConstants";
import { TICKET_ITEM_START_LOADING, TICKET_ITEM_END_LOADING, TICKET_ITEM_LOADED } from "../constants/TicketConstants";
import { TICKET_PRODUCT_START_LOADING, TICKET_PRODUCT_END_LOADING, TICKET_PRODUCT_LOADED } from "../constants/TicketConstants";
import { LINKED_TICKET_START_LOADING, LINKED_TICKET_END_LOADING, LINKED_TICKET_LOADED } from "../constants/TicketConstants";
import { RELATED_TICKET_START_LOADING, RELATED_TICKET_END_LOADING, RELATED_TICKET_LOADED } from "../constants/TicketConstants";
import { TICKET_USER_START_LOADING, TICKET_USER_END_LOADING, TICKET_USER_LOADED } from "../constants/TicketConstants";
import { TICKET_INVOICE_START_LOADING, TICKET_INVOICE_END_LOADING, TICKET_INVOICE_LOADED } from "../constants/TicketConstants";
import { TICKET_INVOICE_CONTRACT_START_LOADING, TICKET_INVOICE_CONTRACT_END_LOADING, TICKET_INVOICE_CONTRACT_LOADED } from "../constants/TicketConstants";
import { TICKET_INVOICE_PRODUCT_START_LOADING, TICKET_INVOICE_PRODUCT_END_LOADING, TICKET_INVOICE_PRODUCT_LOADED } from "../constants/TicketConstants";
import { TICKET_FORM_START_LOADING, TICKET_FORM_END_LOADING, TICKET_FORM_LOADED } from "../constants/TicketConstants";

const initialState = {
    loading: false,
    list: {   
        dataList: []
        , searchList: []
        , totalCount: 0
    },
    notifyList: {   
        dataList: []
        , searchList: []
        , totalCount: 0
    },
    listREVW: {   
        dataList: []
        , searchList: []
        , totalCount: 0
    },
    listSCOC: {   
        dataList: []
        , searchList: []
        , totalCount: 0
    },
    listPCOC: {   
        dataList: []
        , searchList: []
        , totalCount: 0
    },
    listFA: {   
        dataList: []
        , searchList: []
        , totalCount: 0
    },
    listCOS: {   
        dataList: []
        , searchList: []
        , totalCount: 0
    },
    filter: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
        mineOnly: true,
        serviceCode: ""
    },
    notifyFilter: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
        serviceCode: "",
        state: "Upcoming",
        notify: "1"
    },
    filterREVW: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
        serviceCode: "REVW"
    },
    filterSCOC: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
        serviceCode: "SCOC"
    },
    filterPCOC: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
        serviceCode: "PCOC"
    },
    filterFA: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
        serviceCode: "FA"
    },
    filterCOS: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
        serviceCode: "SOC"
    },
    loadingTAG: false,
    tagList: [],
    loadingFBO: false,
    fbo: null,
    loadingItems: false,
    itemList: [],
    loadingProducts: false,
    productList: [],
    loadingLinked: false,
    linkedList: [],
    loadingRelated: false,
    relatedList: [],
    loadingTicketUser: false,
    ticketUserList: [],
    loadingTicketInvoice: false,
    ticketInvoice: null,
    loadingTicketInvoiceContracts: false,
    ticketInvoiceContractList: [],
    loadingTicketInvoiceProducts: false,
    ticketInvoiceProductList: [],
    loadingTicketForm: false,
    ticketFormFBO: []
};

const TicketReducer = (state = initialState, action) => {
    switch(action.type) {
        case TICKET_SEARCH_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case TICKET_SEARCH_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case TICKET_NOTIFY_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case TICKET_NOTIFY_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case TICKET_SEARCH_LOADED:
            return {
                ...state,
                list: action.payload
            };
        case TICKET_NOTIFY_LOADED:
            return {
                ...state,
                notifyList: action.payload
            };
        case TICKET_SEARCH_REVW_LOADED:
            return {
                ...state,
                listREVW: action.payload
            };
        case TICKET_SEARCH_SCOC_LOADED:
            return {
                ...state,
                listSCOC: action.payload
            };
        case TICKET_SEARCH_PCOC_LOADED:
            return {
                ...state,
                listPCOC: action.payload
            };
        case TICKET_SEARCH_FA_LOADED:
            return {
                ...state,
                listFA: action.payload
            };
        case TICKET_SEARCH_SOC_LOADED:
            return {
                ...state,
                listCOS: action.payload
            };
        case SET_TICKET_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        case SET_TICKET_NOTIFY_FILTER:
            return {
                ...state,
                notifyFilter: action.payload
            };
        case SET_TICKET_REVW_FILTER:
            return {
                ...state,
                filterREVW: action.payload
            };
        case SET_TICKET_SCOC_FILTER:
            return {
                ...state,
                filterSCOC: action.payload
            };
        case SET_TICKET_PCOC_FILTER:
            return {
                ...state,
                filterPCOC: action.payload
            };
        case SET_TICKET_FA_FILTER:
            return {
                ...state,
                filterFA: action.payload
            };
        case SET_TICKET_SOC_FILTER:
            return {
                ...state,
                filterCOS: action.payload
            };
        case TICKET_TAG_START_LOADING:
            return {
                ...state,
                loadingTAG: true
            };
        case TICKET_TAG_END_LOADING:
            return {
                ...state,
                loadingTAG: false
            };
        case TICKET_TAG_LOADED:
            return {
                ...state,
                tagList: action.payload
            };
        case TICKET_START_LOADING:
            return {
                ...state,
                loadingFBO: true
            };
        case TICKET_END_LOADING:
            return {
                ...state,
                loadingFBO: false
            };
        case TICKET_LOADED:
            return {
                ...state,
                fbo: action.payload
            };
        case TICKET_ITEM_START_LOADING:
            return {
                ...state,
                loadingItems: true
            };
        case TICKET_ITEM_END_LOADING:
            return {
                ...state,
                loadingItems: false
            };
        case TICKET_ITEM_LOADED:
            return {
                ...state,
                itemList: action.payload
            };
        case TICKET_PRODUCT_START_LOADING:
            return {
                ...state,
                loadingProducts: true
            };
        case TICKET_PRODUCT_END_LOADING:
            return {
                ...state,
                loadingProducts: false
            };
        case TICKET_PRODUCT_LOADED:
            return {
                ...state,
                productList: action.payload
            };
        case LINKED_TICKET_START_LOADING:
            return {
                ...state,
                loadingLinked: true
            };
        case LINKED_TICKET_END_LOADING:
            return {
                ...state,
                loadingLinked: false
            };
        case LINKED_TICKET_LOADED:
            return {
                ...state,
                linkedList: action.payload
            };
        case RELATED_TICKET_START_LOADING:
            return {
                ...state,
                loadingRelated: true
            };
        case RELATED_TICKET_END_LOADING:
            return {
                ...state,
                loadingRelated: false
            };
        case RELATED_TICKET_LOADED:
            return {
                ...state,
                relatedList: action.payload
            };
        case TICKET_USER_START_LOADING:
            return {
                ...state,
                loadingTicketUser: true
            };
        case TICKET_USER_END_LOADING:
            return {
                ...state,
                loadingTicketUser: false
            };
        case TICKET_USER_LOADED:
            return {
                ...state,
                ticketUserList: action.payload
            };
        case TICKET_INVOICE_START_LOADING:
            return {
                ...state,
                loadingTicketInvoice: true
            };
        case TICKET_INVOICE_END_LOADING:
            return {
                ...state,
                loadingTicketInvoice: false
            };
        case TICKET_INVOICE_LOADED:
            return {
                ...state,
                ticketInvoice: action.payload
            };
        case TICKET_INVOICE_CONTRACT_START_LOADING:
            return {
                ...state,
                loadingTicketInvoiceContracts: true
            };
        case TICKET_INVOICE_CONTRACT_END_LOADING:
            return {
                ...state,
                loadingTicketInvoiceContracts: false
            };
        case TICKET_INVOICE_CONTRACT_LOADED:
            return {
                ...state,
                ticketInvoiceContractList: action.payload
            };
        case TICKET_INVOICE_PRODUCT_START_LOADING:
            return {
                ...state,
                loadingTicketInvoiceProducts: true
            };
        case TICKET_INVOICE_PRODUCT_END_LOADING:
            return {
                ...state,
                loadingTicketInvoiceProducts: false
            };
        case TICKET_INVOICE_PRODUCT_LOADED:
            return {
                ...state,
                ticketInvoiceProductList: action.payload
            };
        case TICKET_FORM_START_LOADING:
            return {
                ...state,
                loadingTicketForm: true
            };
        case TICKET_FORM_END_LOADING:
            return {
                ...state,
                loadingTicketForm: false
            };
        case TICKET_FORM_LOADED:
            return {
                ...state,
                ticketFormFBO: action.payload
            };
        default:
            return state;
    }
}

export default TicketReducer;