import React, { Fragment } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Card, CardBody, CardHeader, Col, Container, Row, Label,Table, Input, Spinner } from 'reactstrap';
import BreadCrumb from '../components/Common/BreadCrumb';

import { createDeepClone } from "../utils/index";

import CardLoader from "../components/CardLoader";
import ActionList from "../components/ActionList";
import Lookup from "../components/Lookup";
import { Highlighter, Menu, MenuItem, } from 'react-bootstrap-typeahead';

import Flatpickr from "react-flatpickr";
import dateFormat from "dateformat";
import DocumentGrid from "../components/DocumentGrid";


import { useDispatch, useSelector } from "react-redux";
//import * as productActions from "../actions/ProductActions";

import * as labReportAction from "../actions/LabReportAction";



const LabReportEditScreen  = (props) => {
    const { register, clearErrors, reset, trigger, formState: { errors } } = useForm({mode: 'all'});

    const navigate = useNavigate();
    const { id } = useParams();

    const dispatch = useDispatch();

    const loading = useSelector((state) => state.labReport.loadingFBO);
    const fbo = useSelector((state) => state.labReport.fbo);
    
    const [fboState, setFBOState] = React.useState(fbo);
    const [isDirty, setIsDirty] = React.useState(false);
    const [refreshAction, setRefreshAction] = React.useState(false);
    const [selectedManufacturer, setSelectedManufacturer] = React.useState([]);

    React.useEffect(() => {
	    dispatch(labReportAction.loadLabReport(id, navigate));
    }, [id]);

 
    React.useEffect(() => {
		setFBOState(fbo);
        setIsDirty(false);
        setRefreshAction(!refreshAction);
        setSelectedManufacturer([]);
        
        if(fbo){
            reset(fbo.labReport);

            if(fbo.manufacturer != null) {
                setSelectedManufacturer([fbo.manufacturer]);
            }
        }

        clearErrors();
    }, [fbo]);

    const reloadAction = () => {
        setRefreshAction(!refreshAction);
    }

    const getFormattedDate = (value) => {
        if(value) {
            return dateFormat(value, "mm/dd/yyyy");
        }
        return "";
    }

    const getDateWithoutTimezone = (value) => {
        if(value) {
            const tzoffset = value.getTimezoneOffset() * 60000;
            const withoutTimezone = new Date(value.valueOf() - tzoffset).toISOString().slice(0, -1);
            return withoutTimezone;
        }
        return "";
    }

    const onLabReportChange = (name, value) => {
        var copy_fboState = createDeepClone(fboState);

        if(value) {
            if(name === "dateOfExpiry" || name === "reportDate") {
                copy_fboState.labReport[name] = getDateWithoutTimezone(value);
            }
            else {
                copy_fboState.labReport[name] = value;
            }
        }
        else if(value === false) {
            copy_fboState.labReport[name] = false;
        }
        else {
            copy_fboState.labReport[name] = null;
        }
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onLabReportValueChange = (index, value) => {
        var copy_fboState = createDeepClone(fboState);
        
        if(value) {
            copy_fboState.labReportValues[index]["paramValue"] = value;
        }
        else {
            copy_fboState.labReportValues[index]["paramValue"] = null;
        }

        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const onLabReportValueAdd = (index) => {
        var copy_fboState = createDeepClone(fboState);
        var copy_values = createDeepClone(fboState.labReportValue);
        
        copy_fboState.labReportValues.push(copy_values);
        
        setFBOState(copy_fboState);
        setIsDirty(true);
    }

    const ManufacturerRenderer = (results, menuProps, props) => (
        <Menu id="menuManufacturerLookup">
            <div className="container">
                <div className="row font-weight-bold border-bottom">
                    <div className="col">Organization Name</div>
                </div>
            </div>
            {results.length == 0 && <div style={{padding:5}}>No Macthes Found...</div>}
            {results.map((result, idx) => (
                <MenuItem
                    key={idx}
                    option={result}
                    position={idx}>
                    <div className="row border-bottom">
                        <div className="col" style={{wordBreak: "break-all", whiteSpace: "normal"}}>
                            <Highlighter search={props.text}>
                                {result.organizationName || ''}
                            </Highlighter>
                        </div>
                    </div>
                </MenuItem>
            ))}
        </Menu>
    )

    const onSaveLabReport = async() => {
        const valid = await trigger();
        if(valid) {
            dispatch(labReportAction.saveLabReport(fboState, navigate));
        }
    }

    const onCancelProduct = () => {
        setFBOState(fbo);

        if(fbo.manufacturer != null) {
            setSelectedManufacturer([fbo.manufacturer]);
        }
        else {
            setSelectedManufacturer([]);
        }

        setIsDirty(false);
        clearErrors();
    }
    
    return(
        <Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title={"Edit Lab Report"} />
                    <Row>
                        <Col lg={fboState && fboState.labReport.id > 0 ? 9 : 12}>
                            <CardLoader loading={loading} />

                            <Card className="mb-3">
                                <CardHeader className="border-0 d-flex align-items-center">
                                    <h5 className="card-title mb-0 flex-grow-1">Lab Report</h5>
                                    <div className="flex-shrink-0">
                                        <div className="d-flex flex-wrap gap-2">
                                            <button className="btn btn-primary btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onSaveLabReport}
                                            >
                                                <i className="ri-save-line label-icon align-middle"></i> Save Report
                                            </button>
                                            <button className="btn btn-danger btn-label"
                                                disabled={loading || !isDirty}
                                                onClick={onCancelProduct}
                                            >
                                                <i className="ri-close-line label-icon align-middle"></i> Cancel
                                            </button>
                                        </div>
                                    </div>
                                </CardHeader>
                                <CardBody className="border border-dashed border-end-0 border-start-0">
                                    <Row className="gy-2">
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Lab Name<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.labName ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.labReport.labName) || ""}
                                                {...register("labName", { required: true })}
                                                onChange={e => onLabReportChange("labName", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Product Name<span className="required">*</span></Label>
                                            <input type="text" className={`form-control ${errors.productName ? "is-invalid" : ""}`}
                                                value={(fboState && fboState.labReport.productName) || ""}
                                                {...register("productName", { required: true })}
                                                onChange={e => onLabReportChange("productName", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Brand Name</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.labReport.brandName) || ""}
                                                onChange={e => onLabReportChange("brandName", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">CRM REF#</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.labReport.crmRef) || ""}
                                                onChange={e => onLabReportChange("crmRef", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Date of Expiry</Label>
                                            <Flatpickr className="form-control"
                                                value={(fboState && getFormattedDate(fboState.labReport.dateOfExpiry)) || ""}
                                                onChange={([date]) => onLabReportChange("dateOfExpiry", date)}
                                                options={{
                                                    dateFormat: "m/d/Y"
                                                }}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Report Date</Label>
                                            <Flatpickr className="form-control"
                                                value={(fboState && getFormattedDate(fboState.labReport.reportDate)) || ""}
                                                onChange={([date]) => onLabReportChange("reportDate", date)}
                                                options={{
                                                    dateFormat: "m/d/Y"
                                                }}
                                            />
                                        </Col>
                                        <Col xxl={4} md={6}>
                                            <Label className="form-label">Active</Label>
                                            <select className="form-select"
                                                value={(fboState && fboState.labReport.active) || false}
                                                onChange={e => onLabReportChange("active", e.target.value == "true" ? true : false)}
                                            >
                                                <option value={true}>Yes</option>
                                                <option value={false}>No</option>
                                            </select>
                                        </Col>
                                        <Col xxl={8} md={12}>
                                            <Label className="form-label">Description</Label>
                                            <input type="text" className="form-control"
                                                value={(fboState && fboState.labReport.productDescription) || ""}
                                                onChange={e => onLabReportChange("productDescription", e.target.value)}
                                            />
                                        </Col>
                                        <Col xxl={6} md={6}>
                                            <Label className="form-label">Test Parameter Name(s)</Label>
                                            {fboState && fboState.labReportValues.map(function(item, index){
                                                return(
                                                    <div className="d-flex mb-1" key={index}>
                                                        <div className="flex-grow-1">
                                                            <input className="form-control"
                                                                value={item.paramValue || ""}
                                                                onChange={e => onLabReportValueChange(index, e.target.value)}
                                                            />
                                                        </div>
                                                        <div className="flex-shrink-0" style={{visibility: (index === 0 ? "" : "hidden")}}>
                                                            <button type="button" className="btn btn-primary btn-sm btn-icon rounded-pill ms-1 mt-1" title="Add"
                                                                onClick={() => onLabReportValueAdd(index)}
                                                            >
                                                                <i className="ri-add-line" />
                                                            </button>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>

                            {fboState && fboState.labReport.id &&
                                <DocumentGrid refId={fboState.labReport.id} refName={"LAB_REPORT"} parentId={fboState.labReport.id} parentName={"LAB_REPORT"} canChangeDocument={true} onDocumentAdd={reloadAction} />
                            }

                        </Col>

                        {fboState && fboState.labReport.id > 0 &&
                        <Col lg={3}>
                            <ActionList id={fboState.labReport.id} name={"LAB_REPORT"} refreshAction={refreshAction} />
                        </Col>
                        }
                    </Row>
                </Container>
            </div>
        </Fragment>
    );
}

export default LabReportEditScreen;