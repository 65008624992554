import { SEARCH_PAGE_SIZE } from "../constants/CommonConstants";
import { ISO_REQUEST_SEARCH_START_LOADING, ISO_REQUEST_SEARCH_END_LOADING, ISO_REQUEST_SEARCH_LOADED, SET_ISO_REQUEST_FILTER } from "../constants/IsoRequestConstants";
import { ISO_REQUEST_NOTIFY_START_LOADING, ISO_REQUEST_NOTIFY_END_LOADING, ISO_REQUEST_NOTIFY_LOADED, SET_ISO_REQUEST_NOTIFY_FILTER } from "../constants/IsoRequestConstants";
import { ISO_REQUEST_START_LOADING, ISO_REQUEST_END_LOADING, ISO_REQUEST_LOADED } from "../constants/IsoRequestConstants";
import { RELATED_ISO_REQUEST_START_LOADING, RELATED_ISO_REQUEST_END_LOADING, RELATED_ISO_REQUEST_LOADED } from "../constants/IsoRequestConstants";

const initialState = {
    loading: false,
    list: {   
        dataList: []
        , searchList: []
        , totalCount: 0
    },
    filter: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
    },
    notifyList: {   
        dataList: []
        , searchList: []
        , totalCount: 0
    },
    notifyFilter: {
        pageIndex: 1,
        pageSize: SEARCH_PAGE_SIZE,
        search: "",
        state: "Upcoming",
        notify: "1"
    },
    loadingFBO: false,
    fbo: null,
    loadingRelated: false,
    relatedList: [],
};

const IsoRequestReducer = (state = initialState, action) => {
    switch(action.type) {
        case ISO_REQUEST_SEARCH_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case ISO_REQUEST_SEARCH_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case ISO_REQUEST_SEARCH_LOADED:
            return {
                ...state,
                list: action.payload
            };
        case SET_ISO_REQUEST_FILTER:
            return {
                ...state,
                filter: action.payload
            };
        case ISO_REQUEST_NOTIFY_START_LOADING:
            return {
                ...state,
                loading: true
            };
        case ISO_REQUEST_NOTIFY_END_LOADING:
            return {
                ...state,
                loading: false
            };
        case ISO_REQUEST_NOTIFY_LOADED:
            return {
                ...state,
                notifyList: action.payload
            };
        case SET_ISO_REQUEST_NOTIFY_FILTER:
            return {
                ...state,
                notifyFilter: action.payload
            };
        case ISO_REQUEST_START_LOADING:
            return {
                ...state,
                loadingFBO: true
            };
        case ISO_REQUEST_END_LOADING:
            return {
                ...state,
                loadingFBO: false
            };
        case ISO_REQUEST_LOADED:
            return {
                ...state,
                fbo: action.payload
            };
        case RELATED_ISO_REQUEST_START_LOADING:
            return {
                ...state,
                loadingRelated: true
            };
        case RELATED_ISO_REQUEST_END_LOADING:
            return {
                ...state,
                loadingRelated: false
            };
        case RELATED_ISO_REQUEST_LOADED:
            return {
                ...state,
                relatedList: action.payload
            };
        default:
            return state;
    }
}

export default IsoRequestReducer;